export type { I18nNamespaces } from './autogenerated';
import { I18nNamespaces } from './autogenerated';

// for the possiblity to use string as keyof I18nNamespaces TODO for now.
export declare type LiteralUnion<T extends U, U = string | number | symbol> =
  | T
  | (U extends symbol ? never : U extends number ? never : never)
  | (U & Record<never, never>);

// TODO: enable concatentated keys by namespace and label i.e. 'common:hello'
// type ConcatenatedKeys<T extends Record<string, Record<string, string>>> = {
//   [K in keyof T]: `${Extract<K, string>}:${Extract<keyof T[K], string>}`;
// }[keyof T];
// export type I18nNamespaceSeparatedKeys = ConcatenatedKeys<I18nNamespaces>;

export type N = LiteralUnion<keyof I18nNamespaces>;

type I18nConfigurations = {
  locales: string[];
  defaultLocale: string;
  defaultCountryCode: string;
};

export const I18nConfigurations: I18nConfigurations = {
  locales: ['en', 'da', 'es', 'no'],
  defaultLocale: 'da',
  defaultCountryCode: 'DK',
};
