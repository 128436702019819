import { str } from 'envsafe';
import { envsafeExtended } from '../envsafe-extended';

export const { VERCEL_ENV, VERCEL_URL } = envsafeExtended({
  VERCEL_ENV: str({
    input: process.env.NEXT_PUBLIC_VERCEL_ENV,
    devDefault: 'development',
  }),
  VERCEL_URL: str({
    input: process.env.NEXT_PUBLIC_VERCEL_URL,
    devDefault: 'http://localhost:3000',
  }),
});
