import { envsafe, Validators, Environment } from 'envsafe';

type ExtendedOptions = {
  /**
   * @default process.env
   */
  env?: Environment;
  strict?: boolean;
};

export function envsafeExtended<TCleanEnv>(
  validators: Validators<TCleanEnv>,
  options?: ExtendedOptions,
): Readonly<TCleanEnv> {
  return envsafe<TCleanEnv>(validators, {
    ...options,
    ...(process.env.DISABLE_ENVSAFE && {
      reporter({ errors }: { errors: any }) {
        const keys = Object.keys(errors);
        for (const key of keys) {
          // eslint-disable-next-line no-console
          console.warn(`💨 Missing env ${key} ignored by DISABLE_ENVSAFE`);
        }
      },
    }),
  });
}
